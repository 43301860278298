@use "sass:map";
@import "./../../../styles/colors.scss";
@import "./../../../styles/variables.scss";

.list-item:hover {
    background-color: $surface-1;
    cursor: pointer;
}
.list-item-icon {
    font-size: 2.5rem;
    @media (min-width: map.get($breakpoints,"md")) {
        font-size: 5rem;
    }
}
.list-item-title{
    overflow-wrap: break-word;
}
