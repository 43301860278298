$typeface-weight-small: 400;
$typeface-weight-medium: 500;

$breakpoints: (
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px,
    "xxl": 1400px,
);
