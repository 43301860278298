// Text

.text-align-center {
    text-align: center !important;
}

.text-align-start {
    text-align: start !important;
}

.text-align-end {
    text-align: end !important;
}

// Height

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}
.min-h-100 {
    min-height: 100%;
}
.max-w-100 {
    max-width: 100%;
}

// Display
.d-none {
    display: none;
}

.d-block {
    display: block;
}

@media (prefers-color-scheme: light) {
    .d-light-block {
        display: block !important;
    }
}

@media (prefers-color-scheme: dark) {
    .d-dark-block {
        display: block !important;
    }
}

// Images
.image-container {
    @include surface();
    width: 300px;
    overflow: hidden;
}

.image-container img {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

.image-container-banner {
    @include primary-container();
    width: 300px;
    overflow: hidden;
}

.image-container-banner img {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
}

.image-placeholder {
    @include surface();
    height: 400px;
    width: 400px;
}

.slow-fade-in {
    animation-name: fade-in-animation;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes fade-in-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
